// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom{
    background: white;
    max-height: calc(100% - 100px);
    overflow: hidden;

}`, "",{"version":3,"sources":["webpack://./src/components/pages/pelleting/zoom.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,gBAAgB;;AAEpB","sourcesContent":[".zoom{\n    background: white;\n    max-height: calc(100% - 100px);\n    overflow: hidden;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
