import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseTransfer = async ({ data = {} }) => {
  const url = API.WAREHOUSE_TRANSFERS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseTransferByScan = async ({ data = {} }) => {
  const url = API.WAREHOUSE_TRANSFERS_BY_SCAN;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseTransferQtyUpdate = async ({ data = {} }) => {
  const url = API.WAREHOUSE_TRANSFERS_QTY_UPDATE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehousePackageScan = async ({ data = {} }) => {
  const url = API.WAREHOUSE_PACKAGE_SCAN;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseValidatePicking = async ({ data = {} }) => {
  const url = API.WAREHOUSE_VALIDATE_PICKING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseCheckUncheck = async ({ data = {} }) => {
  const url = API.WAREHOUSE_CHECK_UNCHECK;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const getDeliveryPackage = async ({ data = {} }) => {
  const url = API.GET_DELIVERY_PACKAGE ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const submitDeliveryPackage = async ({ payload = {} }) => {
  const url = API.SUBMIT_PACKAGE ;
  const response = await fetchData({ body: { ...payload }, method: METHODS.POST, url });
  return response;
};

export const createTransferWT = async ({ data = {} }) => {
  const url = API.CREATE_TRANSFER ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const scanLocationkWT = async ({ data = {} }) => {
  const url = API.SCAN_LOCATION ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const loadStockWT = async ({ data = {} }) => {
  const url = API.LOAD_STOCK ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const removeStockWT = async ({ data = {} }) => {
  const url = API.REMOVE_STOCK ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const immediateTransferWT = async ({ data = {} }) => {
  const url = API.IMMEDIATE_TRANSFER ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const immediateUnpackWT = async ({ data = {} }) => {
  const url = API.IMMEDIATE_UNPACK ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const markAsTodoWT = async ({ data = {} }) => {
  const url = API.MARK_AS_TODO_WT ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const updatePhysicallyAvailableQuantityWT = async ({ data = {} }) => {
  const url = API.UPDATE_PHYSICAL_QTY_WT ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const packageScanLoadStockWT = async ({ data = {} }) => {
  const url = API.PACKAGE_SCAN_LOAD_STOCK_WT ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const packageScanWT = async ({ data = {} }) => {
  const url = API.PACKAGE_SCAN_WT ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const finalizePick = async ({ data = {} }) => {
  const url = API.FINALIZE_PICK ;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

