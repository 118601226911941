export const transferDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let transferData = {
      url: obj.name.replaceAll('/', '_'),
      reference: obj.name || 'N/A',
      from: obj.location_id?.display_name || 'N/A',
      to: obj.location_dest_id?.display_name || 'N/A',
      pallet_transfer: obj.pallet_transfer,
      scheduled_date: obj.scheduled_date.split(' ')[0],
      source_document: obj.origin,
      status: obj.state.toUpperCase(),
      data: obj,
    };
    finalData.push(transferData);
  });
  return finalData;
};

export function transferDetailBox(data) {
  let detailBoxData = [
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Origin', col: 1, data: data?.origin || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
    { name: 'State', col: 2, data: data?.state.toUpperCase() || 'N/A' },
  ];
  return detailBoxData;
}

export function wtDetailBox(data) {
  let detailBoxData = [
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    {
      name: 'Destination Location',
      col: 1,
      data: data?.stock_loaded
        ? data?.location_id?.display_name || 'N/A'
        : data?.location_dest_id?.display_name || 'N/A',
    },
    { name: 'Origin', col: 1, data: data?.origin || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
    { name: 'State', col: 2, data: data?.state.toUpperCase() || 'N/A' },
  ];
  return detailBoxData;
}

export function transferDetailBoxPallet(data) {
  let detailBoxData = [
    { name: 'Source Location', col: 1, data: data?.location_id?.barcode || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.barcode || 'N/A' },
    { name: 'Origin', col: 2, data: data?.origin || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    // { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
  ];
  return detailBoxData;
}

export const innerTransferOperationsCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let transferOperationData = {
      product: obj.product_id?.display_name,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
    };
    finalData.push(transferOperationData);
  });
  return finalData;
};

export const innerTransferDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data) {
    data.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            from: moveLine?.location_id?.display_name,
            to: moveLine?.location_dest_id?.display_name,
            blpName: moveLine?.result_package_id?.name || 'N/A',
            checked: moveLine?.checked,
            scan_picked: moveLine?.scan_picked,
            reserve_quantity: moveLine?.quantity,
            done_quantity: moveLine?.done_qty,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      } else {
        let innerTransferData = {
          product: obj.product_id?.display_name,
          from: obj?.location_id?.display_name,
          to: obj?.location_dest_id?.display_name,
          blp: 'N/A',
          checked: false,
          scan_picked: false,
          reserve_quantity: obj?.product_uom_qty,
          done_quantity: obj?.quantity,
          data: obj,
        };
        finalData.push(innerTransferData);
      }
    });
  }
  return finalData;
};

export const innerTransferDetailedOperationPalletTransferCompiler = (data) => {
  let finalData = [];
  if (data) {
    data.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            from: moveLine?.location_id?.barcode,
            to: moveLine?.location_dest_id?.barcode || 'N/A',
            blpName: moveLine?.result_package_id?.name || 'N/A',
            checked: moveLine?.checked,
            scan_picked: moveLine?.scan_picked,
            reserve_quantity: moveLine?.initial_reserve_qty,
            done_quantity: moveLine?.quantity,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      } else {
        let innerTransferData = {
          product: obj.product_id?.display_name,
          from: obj?.location_id?.barcode,
          to: obj?.location_dest_id?.barcode,
          blp: 'N/A',
          checked: false,
          scan_picked: false,
          reserve_quantity: obj?.product_uom_qty,
          done_quantity: obj?.quantity,
          data: obj,
        };
        finalData.push(innerTransferData);
      }
    });
  }
  return finalData;
};

export const innerWtOperationsCompiler = (data) => {
  let finalData = [];
  data?.package_level_ids?.map((obj) => {
    let transferOperationData = {
      stock_loaded: data.stock_loaded,
      id: data?.id,
      blp: obj?.package_id?.name,
      package_id: obj?.id,
      source_location: obj?.location_id?.name,
      destination_location: data?.stock_loaded
        ? obj.location_id?.name
        : obj?.location_dest_id?.name,
      physically_available: obj?.physically_available,
    };
    finalData.push(transferOperationData);
  });
  return finalData;
};

export const wtInnerDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data) {
    data?.move_ids?.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerTransferData = {
            state: data?.state,
            move_line_id: moveLine?.id,
            product: obj.product_id?.display_name,
            from: moveLine?.location_id?.display_name,
            to: data?.stock_loaded
              ? moveLine?.location_id?.display_name
              : moveLine?.location_dest_id?.display_name,
            on_hand_quantity: moveLine?.on_hand_qty,
            physically_available_quantity: moveLine?.physical_available_qty,
            done_quantity: moveLine?.done_qty,
            status: false,
            data: obj,
          };
          finalData.push(innerTransferData);
        });
      }
    });
  }
  return finalData;
};
