import React, { useEffect, useState } from 'react';
import { DropDown, Scanner, SearchBar, Table, TitleBar } from '../../common';
import { ROUTES, DELIVERY_COLUMN, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { getSortingArray, popup } from '../../../utils';
import { outgoingScanDelivery, warehouseDelivery } from '../../../service';
import { deliveryDataCompiler } from '../../../utils/data_compiler/deliveryDataCompiler';
import { ScanIcon } from '../../../assets/icons';
import { Button } from '../../shared';

export const Deliveries = () => {
  const dispatch = useDispatch();
  const [deliveryData, setDeliveryData] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(DELIVERY_COLUMN[5].name);
  const [sortingArray1, setSortingArray1] = useState([]);

  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDeliveryList(data);
    } else if (!searchTerm && !Array.isArray(deliveryData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDeliveryList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort1(DELIVERY_COLUMN[5].name);
    dispatch(setLoaderVisibility(true));
    getDeliveryList();
  };

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getDeliveryList();
    }
  }, [searchTerm]);

  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray1.length > 1) {
      data = {
        state: `${sortingArray1.includes(sort1) ? (sort1.toLowerCase() === 'n/a' ? false : sort1.toLowerCase()) : ''}`,
      };
      getDeliveryList(data);
    }
  }, [sort1]);

  const startScanning = () => {
    setScanning((prev) => !prev);
  };

  // API Calling
  const getDeliveryList = useDebounce((data = {}) => {
    warehouseDelivery({
      data
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setDeliveryData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: DELIVERY_COLUMN[5],
                data: response.data,
                compiler: deliveryDataCompiler,
              }),
            );
          }
        } else {
          setDeliveryData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleOutgoingDeliveryScan = useDebounce((scanData) => {
    dispatch(setLoaderVisibility(true));
    outgoingScanDelivery({
      data: { sale_order_ref: scanData },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        getDeliveryList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDeliveryList();
  }, []);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      handleOutgoingDeliveryScan(scanData);
    }
  }, [scanData]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.DELIVERY, route: ROUTES.DELIVERIES }]} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start gap-3 w-100">
          <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
          <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
            {sortingArray1.includes(sort1) && (
              <Button
                clickHandler={resetFilter}
                className="blue-btn d-flex align-items-center justify-content-center px-3">
                {BUTTON.CLEAR_FILTERS}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {scanning && <Scanner setScanData={setScanData} />}
          <Button
            className={`white-btn d-flex align-items-center justify-content-center px-3 mb-3`}
            clickHandler={startScanning}>
            {scanning ? BUTTON.STOP_SCAN : BUTTON.OUTGOING_DELIVERY_SCAN} <ScanIcon />
          </Button>
        </div>
      </div>
      {Array.isArray(deliveryData) ? (
        <Table
          columns={DELIVERY_COLUMN}
          initialData={deliveryData}
          PAGE_SIZE={10}
          to={ROUTES.DELIVERY_DETAIL}
          compiler={{ use: true, tool: deliveryDataCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{deliveryData}</h5>
        </div>
      )}
    </>
  );
};
