export const randomNum = (max, min) => {
  let rand = Math.floor(Math.random() * (max - min + 1) + min);
  return rand;
};

export const randomDate = () => {
  const start = new Date(2023, 1, 1);
  const end = new Date();
  const dateGen = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  };
  return convertDate(dateGen(start, end));
};

export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? '0' + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

export const getSortingArray = ({ column, data, compiler }) => {
  if (Array.isArray(data) && data.length) {
    let newData = compiler(data);
    let dataField = column.dataField[0];
    let valueArray = newData
      .map((obj) => obj[dataField])
      .reduce((acc, val) => {
        if (!acc.includes(val[0].toUpperCase() + val.slice(1).replaceAll('_', ' '))) {
          acc.push(val[0].toUpperCase() + val.slice(1).replaceAll('_', ' '));
        }
        return acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : 1));
    return valueArray;
  }
};

export const sortingByName = ({ sortBy, data, order }) => {
  data.sort((a, b) => {
    const nameA = a[sortBy]?.toLowerCase();
    const nameB = b[sortBy]?.toLowerCase();
    // Compare the names
    if (nameA < nameB) {
      return order ? 1 : -1;
    }
    if (nameA > nameB) {
      return order ? -1 : 1;
    }
    return 0;
  });
  return data;
};

export const sortingByValue = ({ sortBy, data, order }) => {
  const [sortBy1, sortBy2] = sortBy;
  if (sortBy.length > 1) {
    data.sort((a, b) => {
      const valueA = a[sortBy1] - a[sortBy2];
      const valueB = b[sortBy1] - b[sortBy2];
      return order ? valueB - valueA : valueA - valueB;
    });
    return data;
  } else {
    data.sort((a, b) => {
      const valueA = a[sortBy1];
      const valueB = b[sortBy1];
      return order ? valueB - valueA : valueA - valueB;
    });
    return data;
  }
};