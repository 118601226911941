import  React from 'react';

export const BlueCheck = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1171_8066)">
      <path
        d="M10.5001 0.5C4.986 0.5 0.5 4.98594 0.5 10.4999C0.5 16.0139 4.986 20.4999 10.5001 20.4999C16.0141 20.4999 20.5 16.0139 20.5 10.4999C20.5 4.98594 16.0141 0.5 10.5001 0.5ZM10.5001 18.8605C5.88993 18.8605 2.13933 15.1101 2.13933 10.4999C2.13933 5.88987 5.88993 2.13933 10.5001 2.13933C15.1101 2.13933 18.8607 5.88987 18.8607 10.4999C18.8607 15.1101 15.1101 18.8605 10.5001 18.8605Z"
        fill="#007BFF"
      />
      <path
        d="M14.8257 6.66191L8.88827 12.5994L6.17367 9.88478C5.85354 9.56471 5.33459 9.56471 5.01446 9.88478C4.6944 10.2049 4.6944 10.7239 5.01446 11.044L8.30867 14.3382C8.46873 14.4982 8.6785 14.5783 8.88827 14.5783C9.09804 14.5783 9.30781 14.4982 9.46788 14.3382L15.9849 7.82119C16.3049 7.50106 16.3049 6.98211 15.9849 6.66198C15.6648 6.34185 15.1457 6.34185 14.8257 6.66191Z"
        fill="#007BFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1171_8066">
        <rect width={20} height={20} fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg> 
);


