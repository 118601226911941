import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import sliderIcon from '../../assets/img/slider-btn.webp';
import {
  DashboardIcon,
  ShipmentLogo,
  PelletingLogo,
  TransferLogo,
  DeliveryLogo,
} from '../../assets/icons';
import { HEADING, ROUTES, SCANOPTIONS } from '../../constants';
import { Button } from '../shared';
import SettingsIcon from '../../assets/icons/SettingsIcon';
import { PopUpBox } from './PopupBox';

export const Sidebar = ({ currentDashboardRoute, setCurrentDashboardRoute }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const dropdownRef = useRef(null);

  const settingsMenu = [SCANOPTIONS.SCAN_GUN, SCANOPTIONS.CAMERA];

  const [isCameraScan, setIsCameraScan] = useState(() => {
    const storedValue = localStorage.getItem('isCameraScan');
    return storedValue !== null ? storedValue === 'true' : false;
  });

  const [scanType, setScanType] = useState(
    isCameraScan ? SCANOPTIONS.CAMERA : SCANOPTIONS.SCAN_GUN,
  );
  const navigate = useNavigate();

  const sidebarOptions = [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      route: ROUTES.DASHBOARD,
    },
    {
      name: 'Shipment',
      icon: ShipmentLogo,
      route: ROUTES.SHIPMENT,
    },
    {
      name: 'Palleting',
      icon: PelletingLogo,
      route: ROUTES.PELLETING,
    },
    {
      name: 'Transfers',
      icon: TransferLogo,
      route: ROUTES.TRANSFERS,
    },
    {
      name: 'Deliveries',
      icon: DeliveryLogo,
      route: ROUTES.DELIVERIES,
    },
  ];

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingOpen(false);
    }
  };

  const handleSettings = () => {
    setIsSettingOpen(!isSettingOpen);
  };

  const handleScanType = (type) => {
    type === SCANOPTIONS.CAMERA ? setIsCameraScan(true) : setIsCameraScan(false);
    setScanType(type);
    setIsSettingOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('isCameraScan', isCameraScan);
  }, [isCameraScan]);

  const scanTypeSelector = (
    <div ref={dropdownRef} className="w-100 ">
      <h5 className="mb-3 bg-secondary p-2 text-light">{HEADING.SELECT_SCAN_MODE}</h5>
      {settingsMenu.map((item, index) => {
        return (
          <div key={index} className="form-check d-flex align-items-center justify-content-start">
            <input
              className="form-check-input fs-6 me-2"
              type="radio"
              name="flexRadioDefault"
              onChange={() => handleScanType(item)}
              id="flexRadioDefault1"
              checked={scanType === item}
            />
            <label className="form-check-label fs-6" htmlFor="flexRadioDefault1">
              {item}
            </label>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Button
        className={`slider-btn position-absolute d-flex align-items-center justify-content-center d-lg-none d-block bg-white p-1 ${
          showSidebar ? 'open' : ''
        }`}
        clickHandler={toggleSidebar}
        type="button"
        id="toggleButton">
        <img src={sliderIcon} alt="arrow-icon" width="14" height="14" />
      </Button>
      <div
        className={`sidebar-main-view flex-column d-flex align-items-start justify-content-between bg-white ${
          showSidebar ? 'show' : ''
        }`}
        id="MenuSidebarMobileView2">
        <div className="w-100">
          <ul className="ps-0 w-100">
            {sidebarOptions.map((option, index) => (
              <li
                key={index}
                className={`sidebar-list d-flex align-items-center position-relative ${
                  option.route === currentDashboardRoute ? 'active' : ''
                }`}
                onClick={() => {
                  setCurrentDashboardRoute(option.route);
                  navigate(option.route);
                }}>
                <option.icon />
                <span>{option.name}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-100">
          <li
            className={`sidebar-list d-flex align-items-center `}
            onClick={() => handleSettings()}>
            <SettingsIcon />
            <span>Settings</span>
          </li>
          <PopUpBox openPopUpBox={isSettingOpen}>{scanTypeSelector}</PopUpBox>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  currentDashboardRoute: PropTypes.string.isRequired,
  setCurrentDashboardRoute: PropTypes.func.isRequired,
};
