import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TableHead, TableBody } from '../shared';
import { useIntersectionObserver } from '../../utils/useIntersectionObserver';
import { sortingByName, sortingByValue } from '../../utils';
import { SORTING } from '../../constants';

export const Table = ({
  columns,
  initialData = [],
  PAGE_SIZE,
  colFilter,
  to,
  compiler,
  popUp,
  setPopUp,
  setPopUpData,
  setReload,
  func,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(columns.map((col) => col.name));
  const [sortInfo, setSortInfo] = useState({
    sortType: SORTING.DEFAULT,
    index: 0,
    valueType: '',
    value: 0,
  });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const tableEndRef = useRef(null);

  const finalColumns = columns.filter((column) => selectedColumns.includes(column.name));

  // Initialize data and check if there's more to load
  useEffect(() => {
    setData((Array.isArray(initialData)) ? initialData.slice(0, PAGE_SIZE): initialData);
    setHasMore(initialData.length > PAGE_SIZE);
    setPageNumber(1);
  }, [initialData, PAGE_SIZE]);

  const loadMoreData = useCallback(() => {
    if (!hasMore || isLoading) return;
    setIsLoading(true);
     const newData = initialData.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    setData((prevData) => [...prevData, ...newData]);
    setHasMore(initialData.length > (pageNumber + 1) * PAGE_SIZE);
    setPageNumber(pageNumber + 1); // Update page number after loading new data
    setIsLoading(false);
  }, [pageNumber, initialData, PAGE_SIZE, hasMore, isLoading]);

  // Trigger loadMoreData when the observer reaches the end of the table
  useIntersectionObserver({
    target: tableEndRef,
    onIntersect: loadMoreData,
    enabled: hasMore && !isLoading,
    options: { rootMargin: '200px', threshold: 0.1 },
  });
  // Sorting handler function
  const handleSort = useCallback(
    ({ sortInfo, data }) => {
      const { sortType, index, valueType } = sortInfo;
      let result = [];
      const dataFieldName = columns[index]?.dataField;

      if (valueType === 'String') {
        result = sortingByName({
          data: data,
          sortBy: dataFieldName,
          order: sortType === SORTING.ASCENDING ? 1 : 0,
        });
      } else {
        result = sortingByValue({
          data: data,
          sortBy: dataFieldName,
          order: sortType === SORTING.ASCENDING ? 0 : 1,
        });
      }
      return result;
    },
    [columns],
  );

  return (
    <div className="table-container table-1 position-relative mb-3">
      <table className="w-100">
        <TableHead
          columns={columns}
          showColumn={finalColumns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          colFilter={colFilter}
          sortInfo={sortInfo}
          setSortInfo={setSortInfo}
        />
        <TableBody
          columns={finalColumns}
          data={data}
          colFilter={colFilter}
          to={to !== '' ? to : undefined}
          compiler={compiler}
          sortInfo={sortInfo}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
          setReload={setReload}
          handleSort={handleSort}
          func={func}
        />
      </table>
      <div ref={tableEndRef} className="border intersection-observer-trigger">
        {isLoading && <div>Loading more data...</div>}
      </div>
    </div>
  );
};
