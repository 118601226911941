import { popup } from '../../../utils';
import { POPUP_MSG, POPUP_TYPE } from '../../../constants';
import { packagePlacing, createGrid } from '../../../service';
import scan_success from '../../../assets/sounds/scan_success.mp3';
import scan_failed from '../../../assets/sounds/error.mp3';
export const PalletingFlow = ({
  scanData,
  packageData,
  pelletData,
  setScanData,
  packageScanned,
  setPackageScanned,
  setSelectedPellet,
  dispatch,
  setLoaderVisibility,
  setReload,
  tileData,
  gridData,
  selectedPellet,
  state,
  setConfirmPopUp,
}) => {
  let successAudio = new Audio(scan_success);
  let failedAudio = new Audio(scan_failed);

  if (scanData) {
    if (scanData.startsWith('000084') || scanData.startsWith('PACK')) {
      const pallet = packageData?.filter((pack) => pack.package_id.name === scanData);
      if (pallet.length > 0 && pallet[0].state != 'moved') {
        if (pallet.length && packageScanned.map((pack) => pack.id).includes(pallet[0].id)) {
          popup(POPUP_TYPE.ERR, POPUP_MSG.RE_SCANNED);
        }
        if (
          tileData.map((tile) => tile.pellet?.pellet_id).includes(pallet[0].pallet_id.id) ||
          tileData.filter((tile) => !tile.pellet).length
        ) {
          successAudio.play();

          if (
            packageScanned.length > 0 &&
            packageScanned[0].pallet_id === pallet[0].pallet_id.id &&
            packageScanned.every((pack) => pack.state === 'assigned') &&
            pallet[0].state === 'assigned'
          ) {
            
            setSelectedPellet({
              ...pelletData.pellets?.filter((pal) => pal.pellet_id == pallet[0].pallet_id.id)[0],
              tile:
                tileData.filter((tile) => tile.pellet.pellet_id === pallet[0].pallet_id.id)[0] ||
                tileData.filter((tile) => tile.pellet === false)[0],
              count: packageScanned.length,
            });
          } else if (
            packageScanned.length > 1 &&
            (packageScanned[0].pallet_id !== pallet[0].pallet_id.id ||
              pallet[0].state !== 'assigned')
          ) {
            setConfirmPopUp(true);
            return;
          } else {
            setPackageScanned([
              {
                id: pallet[0].id,
                state: pallet[0].state,
                pallet_id: pallet[0].pallet_id.id,
                package: scanData,
              },
            ]);
            setSelectedPellet({
              ...pelletData.pellets?.filter((pal) => pal.pellet_id == pallet[0].pallet_id.id)[0],
              tile:
                tileData.filter((tile) => tile.pellet.pellet_id === pallet[0].pallet_id.id)[0] ||
                tileData.filter((tile) => tile.pellet === false)[0],
              count: packageScanned.length,
            });
          }
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.TILE_NOT_AVAILABLE);
          setScanData('');
          failedAudio.play();
        }
      } else if (
        packageScanned.length &&
        pallet.length &&
        (packageScanned[0].pallet_id === pallet[0].pallet_id.id || pallet[0].state !== 'assigned')
      ) {
        setConfirmPopUp(true);
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_PACKAGE_SCANNED);
        setScanData('');
        failedAudio.play();
      }
    } else if (scanData.startsWith('Tile')) {
      if (packageScanned.length) {
        const pack = packageData.filter((pack) =>
          packageScanned.map((scanPack) => scanPack.package).includes(pack?.package_id?.name),
        );
        if (pack.length && pack.every((pck) => pck.state === 'assigned')) {
          if (selectedPellet.tile.display_name === scanData) {
            successAudio.play();
            dispatch(setLoaderVisibility(true)),
              packagePlacing({
                data: {
                  pallet_process_ids: pack.map((pck) => pck.id),
                  type: 'placed',
                },
              })
                .then((response) => {
                  popup(
                    POPUP_TYPE.SUCCESS,
                    POPUP_MSG.PACKAGE_PLACED(
                      packageScanned.length < 2
                        ? packageScanned[0].package
                        : `${packageScanned.length} package`,
                      response.data[0].state,
                      selectedPellet.tile.display_name,
                    ),
                  ),
                    setPackageScanned(''),
                    setReload(true),
                    createGrid({
                      data: {
                        shipment_id: state?.id,
                        grid_data: gridDataConvertor(gridData), // Send the entire updated gridData here
                      },
                    }).then(() => {
                      setSelectedPellet({});
                      setScanData('');
                    });
                })
                .catch((error) => {
                  dispatch(setLoaderVisibility(false));
                  popup(POPUP_TYPE.ERR, error.message);
                });
          } else {
            popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_PALLET_SCANNED);
            setScanData('');
            failedAudio.play();
          }
        } else if (pack?.state === 'placed') {
          popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_PLACED), setSelectedPellet({});
          setScanData('');
          setPackageScanned('');
          failedAudio.play();
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.PLACED_AND_VERIFIED);
          setSelectedPellet({});
          setScanData('');
          setPackageScanned('');
          failedAudio.play();
        }
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.SCAN_PACKAGE_FIRST);
        failedAudio.play();
      }
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
      setScanData('');
      failedAudio.play();
    }
  }
};

export const packageVerification = ({
  dispatch,
  setLoaderVisibility,
  process_ids,
  setReload,
  msg,
  setPopUpPellet,
  pelletData,
  selectedPellet,
  setScanData,
  apiCall,
}) => {
  dispatch(setLoaderVisibility(true)),
    apiCall({
      data: {
        pallet_process_ids: process_ids,
        type: 'verified',
      },
    })
      .then(() => {
        setReload(true);
        popup(POPUP_TYPE.SUCCESS, msg);
      })
      .then(() => {
        setPopUpPellet(pelletData.pellets.filter((pal) => pal.pellet_id === selectedPellet)[0]);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  setScanData('');
};

export const gridDataCreateUpdateConvertor = (gridData, existingGrid) => {
  let palletList = [];
  if (Object.keys(existingGrid).length) {
    palletList = existingGrid.pallets.map((pal) => pal.list.map((lst) => lst)).flat();
  }
  let count = 0;
  const result = gridData.pallets.reduce((ACC, KEY, index) => {
    const arr = Array.from({ length: KEY.pallet });
    ACC[`${index + 1}`] = {
      ...arr.reduce((acc, key, ind) => {
        acc[`${ind + 1}`] = palletList[count]?.pallet_name || '';
        count++;
        return acc;
      }, {}),
    };
    return ACC;
  }, {});
  return result;
};

export const tileCount = (gridData, index, ind) => {
  let count = 0;
  if (index === 0) {
    return count + ind;
  } else {
    for (let i = index; i >= 1; i--) {
      count += gridData.pallets[i - 1].list?.length;
    }

    return count + ind;
  }
};

export const gridDataConvertor = (gridData) => {
  const result = {};
  gridData.pallets.forEach((palletInfo) => {
    const { rowIndex, pallet, list } = palletInfo;
    result[`${rowIndex}`] = {};
    for (let colIndex = 1; colIndex <= pallet; colIndex++) {
      const palletData = list[colIndex - 1];
      result[`${rowIndex}`][`${colIndex}`] =
        palletData && palletData.pallet_name ? palletData.pallet_name : '';
    }
  });

  return result;
};

export const findEmpty = ({ gridData, selectedPellet }) => {
  if (gridData?.rows > 0 && Object.entries(selectedPellet).length > 0) {
    const updatedGridData = { ...gridData, pallets: [...gridData.pallets] };
    let emptyTileFound = false;
    let selectedPelletExists = false;
    for (let i = 0; i < updatedGridData?.pallets?.length; i++) {
      for (let j = 0; j < updatedGridData?.pallets[i]?.list?.length; j++) {
        const cell = updatedGridData?.pallets[i]?.list[j];
        if (cell?.pallet_id === selectedPellet?.pellet_id) {
          selectedPelletExists = true;
          return {
            updatedGridData,
            existingPosition: { rowIndex: i, colIndex: j },
          };
        }
      }
    }
    if (!selectedPelletExists) {
      for (let i = 0; i < updatedGridData?.pallets?.length && !emptyTileFound; i++) {
        for (let j = 0; j < updatedGridData?.pallets[i]?.list?.length && !emptyTileFound; j++) {
          const cell = updatedGridData?.pallets[i]?.list[j];
          if (cell.pallet_id === false) {
            updatedGridData.pallets[i].list[j] = {
              ...cell,
              pallet_name: selectedPellet?.pellet_name,
              pallet_id: selectedPellet?.pellet_id,
            };
            emptyTileFound = true;
            console.log(`Empty tile found and filled at row ${i}, column ${j}`);
            return {
              updatedGridData,
              newPelletPosition: {
                rowIndex: i,
                colIndex: j,
                pallet_name: selectedPellet?.pellet_name || '',
              },
            };
          }
        }
      }
      if (!emptyTileFound) popup(POPUP_TYPE.ERR, 'No Pallet remaining to map the package.');
    }
  }
  return {};
};

export const getTileNumber = (updatedGridData) => {
  let count = 0;
  for (let i = 0; i < updatedGridData?.pallets?.length; i++) {
    const row = updatedGridData?.pallets[i];
    for (let j = 0; j < row?.list?.length; j++) {
      const tile = row?.list[j];
      count++;
      if (tile?.pallet_id === false) {
        return count;
      }
    }
  }
  return null;
};

export const getTileNumberDetailBox = (updatedGridData, selectedPellet) => {
  let count = 0;
  // if (updatedGridData?.pellets === undefined) {
  //   return;
  // } else {
  for (let i = 0; i < updatedGridData?.pallets?.length; i++) {
    const row = updatedGridData?.pallets[i];
    for (let j = 0; j < row?.list?.length; j++) {
      const tile = row?.list[j];
      count++;
      if (tile?.pallet_id === selectedPellet?.pellet_id) {
        return count;
      }
      if (!tile?.pallet_id) {
        return count;
      }
    }
  }
  return null;
  // }
};

export const getTileNumberVerificationModal = (updatedGridData, selectedPellet) => {
  for (let i = 0; i < updatedGridData?.pallets?.length; i++) {
    const row = updatedGridData?.pallets[i];
    for (let j = 0; j < row?.list?.length; j++) {
      const tile = row?.list[j];
      if (tile?.pallet_id === selectedPellet) {
        return tile?.displayName;
      }
    }
  }
  return null;
};
